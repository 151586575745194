import { doc, type DocumentReference, type Firestore } from "firebase/firestore"

export interface Reference {
  path: string
  id: string
}

export namespace Reference {
  export function generate(ref: DocumentReference): Reference {
    return {
      path: ref.path,
      id: ref.id,
    }
  }

  export function toClass(firestore: Firestore, ref: Reference) {
    return doc(firestore, ref.path)
  }

  export function isReference(x: unknown): x is Reference {
    return typeof x === "object" && x !== null && "path" in x && "id" in x
  }
}
