import { firestoreConverter } from "@/domain/core/converters/client_firestore_converter"
import {
  collection,
  collectionGroup,
  doc,
  query,
  type CollectionReference,
  type DocumentData,
  type DocumentReference,
  type Firestore,
  type FirestoreDataConverter,
  type Query,
  type QueryConstraint,
} from "firebase/firestore"

export class FirebaseFirestoreInfra {
  firestore: Firestore

  constructor(firestore: Firestore) {
    this.firestore = firestore
  }

  collectionReference<T extends DocumentData>(
    path: string,
    converter?: FirestoreDataConverter<T>
  ): CollectionReference<T> {
    return collection(this.firestore, path).withConverter<T>(
      converter ?? firestoreConverter<T>()
    )
  }

  //
  query<T extends DocumentData>(
    path: string,
    queryConstraints: QueryConstraint[] = [],
    converter?: FirestoreDataConverter<T>
  ): Query<T> {
    return query(
      collection(this.firestore, path),
      ...queryConstraints
    ).withConverter(converter ?? firestoreConverter<T>())
  }

  documentReference<T extends DocumentData>(
    path: string,
    converter?: FirestoreDataConverter<T>
  ): DocumentReference<T> {
    return doc(this.firestore, path).withConverter<T>(
      converter ?? firestoreConverter<T>()
    )
  }

  collectionGroupReference<T extends DocumentData>(
    collectionId: string,
    converter?: FirestoreDataConverter<T>
  ): Query<T, DocumentData> {
    return collectionGroup(this.firestore, collectionId).withConverter(
      converter ?? firestoreConverter<T>()
    ) as Query<T, DocumentData>
  }
}
