/**
 * 会社情報
 */
export interface Company {
  name: string
  companyId: string
  createdAt: Date
  updatedAt: Date
  imageStoragePath?: string
  expiredAt?: Date
}

export namespace Company {
  export namespace firestore {
    export const collectionPath = "Company/v2/companies"
  }
}
