import { CompanyRepository } from "@/domain/features/company/v2/repositories/company_repository"
import { WorkingCompanyRepository } from "@/domain/features/company/v2/repositories/working_company_repository"
import { type FirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/core/firestore"

export async function fetchCurrentWorkingCompany({
  userId,
  firestore,
}: {
  userId: string | undefined
  firestore: FirebaseFirestoreInfra
}) {

  if (userId == null) {
    return undefined
  }
  // 所属企業の取得
  const { companyId } =
    (await new WorkingCompanyRepository(firestore).fetchOrNot({ userId })) ??
    {}

  if (companyId == null) {
    return undefined
  }

  // 企業情報の取得
  return await new CompanyRepository(firestore).fetchById({ companyId })
}