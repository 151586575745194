/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { isLatLng } from "@/domain/core/entities/lat_lng"
import { Reference } from "@/domain/core/entities/reference"
import {
  DocumentReference,
  FieldValue,
  GeoPoint,
  getFirestore,
  type DocumentData,
  type FirestoreDataConverter,
  type Timestamp,
} from "firebase/firestore"

export function firestoreConverter<T extends DocumentData>(
  readonlyId?: keyof T
) {
  return {
    fromFirestore(snapshot) {
      const data = snapshot.data()

      const id = readonlyId != null ? { readonlyId: snapshot.id } : {}

      return FirestoreRepositoryJsonConverter.instance.fromFirestore<T>({
        ...data,
        ...id,
      })
    },
    toFirestore(obj) {
      let data = { ...obj }

      if (readonlyId != null) {
        data = Object.keys(obj)
          .filter((key) => {
            return key !== readonlyId
          })
          .reduce((pre, cur) => {
            return {
              ...pre,
              cur: obj[cur],
            }
          }, {})
      }

      return FirestoreRepositoryJsonConverter.instance.toFirestore(data)
    },
  } as const satisfies FirestoreDataConverter<T>
}

/**
 * Firestore と JS の class を上手くデータのやり取りをさせるための class
 *
 * toFirestore と fromFirestore を提供する
 */
class FirestoreRepositoryJsonConverter {
  constructor() {}
  static instance = new FirestoreRepositoryJsonConverter()
  /**
   * transaction で使用するために public に設定している。
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public toFirestore = (item: any): Record<string, unknown> => {
    const objectGetters = this.extractAllGetters(item)

    const serializableObj = { ...item, ...objectGetters }

    Object.entries(serializableObj).forEach(([propertyKey, _]) => {
      const value = serializableObj[propertyKey]

      // undefined: 削除
      if (value === undefined) {
        delete serializableObj[propertyKey]
      }
      // 配列: 各要素に this.toJson() + undefined の削除
      else if (Array.isArray(value)) {
        serializableObj[propertyKey] = value
          .map((e: unknown) => {
            if (isObject(e)) {
              return this.toFirestore(e)
            }

            return e
          })
          .filter((e) => e !== undefined)
      } else if (isLatLng(value)) {
        serializableObj[propertyKey] = new GeoPoint(
          value.latitude,
          value.longitude
        )
      } else if (Reference.isReference(value)) {
        serializableObj[propertyKey] = Reference.toClass(getFirestore(), value)
      } else if (
        value instanceof Date ||
        value instanceof DocumentReference ||
        value instanceof GeoPoint ||
        value instanceof FieldValue
      ) {
        // skip
      }
      // object: this.toJson() の実行
      else if (isObject(value)) {
        serializableObj[propertyKey] = this.toFirestore(
          serializableObj[propertyKey] as Record<string, unknown>
        )
      }
    })

    return serializableObj
  }

  /**
   * transaction 、バックグラウンド関数で取得した snapshot を加工するために、public にしている。
   */
  public fromFirestore = <T>(data: DocumentData): T => {
    return this.encodeFirestoreTypes(data) as T
  }

  /**
   * Firestore 独自の型を、JavaScript の型に変換
   *
   * firestore.DocumentReference、firestore.GeoPoint はそのまま
   *
   * @param obj param
   * @returns
   */
  private encodeFirestoreTypes = (
    obj: Record<string, unknown>
  ): Record<string, unknown> => {
    Object.keys(obj).forEach((key) => {
      const val = obj[key]
      if (val === undefined || val === null) return
      if (!obj[key]) return
      if (!isObject(val)) {
        return
      } else if (isTimestamp(val)) {
        obj[key] = val.toDate()
      } else if (isGeoPoint(val)) {
        obj[key] = { latitude: val.latitude, longitude: val.longitude }
      } else if (isDocumentReference(val)) {
        obj[key] = Reference.generate(val)
      } else if (Array.isArray(val)) {
        obj[key] = val.map((item) => this.encodeFirestoreTypes(item))
      } else {
        obj[key] = this.encodeFirestoreTypes(val)
      }
    })

    return obj
  }

  /**
   * ゲッターや関数を取り除く
   *
   * @param obj param
   * @returns
   */
  private extractAllGetters = (obj: Record<string, unknown>) => {
    const prototype = Object.getPrototypeOf(obj)
    const fromInstanceObj = Object.keys(obj)
    const fromInstance = Object.getOwnPropertyNames(obj)
    const fromPrototype = Object.getOwnPropertyNames(Object.getPrototypeOf(obj))

    const keys = [...fromInstanceObj, ...fromInstance, ...fromPrototype]

    const getters = keys
      .map((key) => Object.getOwnPropertyDescriptor(prototype, key))
      .map((descriptor, index) => {
        // FieldValue は残す
        if (descriptor instanceof FieldValue) {
          return keys[index]
        }
        if (descriptor && typeof descriptor.get === "function") {
          return keys[index]
        } else {
          return undefined
        }
      })
      .filter((d) => d !== undefined)

    return getters.reduce<Record<string, unknown>>(
      (accumulator, currentValue) => {
        if (typeof currentValue === "string" && obj[currentValue]) {
          accumulator[currentValue] = obj[currentValue]
        }

        return accumulator
      },
      {}
    )
  }
}

export function isTimestamp(x: unknown): x is Timestamp {
  return isObject(x) && "toDate" in x
}

export function isGeoPoint(x: unknown): x is GeoPoint {
  return (
    isObject(x) &&
    (("_lat" in x && "_long" in x) || ("latitude" in x && "longitude" in x))
  )
}

export function isDocumentReference(x: unknown): x is DocumentReference {
  return isObject(x) && "path" in x
}

export function isObject(x: unknown): x is Record<string, unknown> {
  return x !== null && typeof x === "object"
}
