import { type Auth } from "firebase/auth"

class FirebaseAuthInfra {
  constructor(auth: Auth) {
    this._auth = auth
  }
  private _auth: Auth

  get auth(): Auth {
    return this._auth
  }
}

export default FirebaseAuthInfra
