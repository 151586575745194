"use client"

import { useEffect } from "react"
import { firebaseConfig } from "@/infrastructure/firebase/config"

export default function FirebaseSessionProvider() {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      const serializedFirebaseConfig = encodeURIComponent(
        JSON.stringify(firebaseConfig)
      )
      const serviceWorkerUrl = `/auth-service-worker.js?firebaseConfig=${serializedFirebaseConfig}`

      navigator.serviceWorker.register(serviceWorkerUrl).catch(console.error)
    } else {
      console.warn("No Service Worker")
    }
  }, [])

  return <></>
}
