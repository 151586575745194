import { type FirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/core/firestore"
import {
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  type Unsubscribe,
} from "firebase/firestore"

import { CompanyAdmin } from "../entities/company_admin/company_admin"

export class CompanyAdminRepository {
  constructor(private readonly firestore: FirebaseFirestoreInfra) {}

  private collectionReference(
    params: CompanyAdmin.firestore.ParameterOfCollectionPath
  ) {
    return this.firestore.collectionReference<CompanyAdmin>(
      CompanyAdmin.firestore.collectionPath(params)
    )
  }
  async exists(
    params: CompanyAdmin.firestore.ParameterOfCollectionPath & {
      userId: string
    }
  ) {
    const documentReference = doc(
      this.collectionReference(params),
      params.userId
    )
    const snapshot = await getDoc(documentReference)

    return snapshot.exists()
  }

  async save(
    userId: string,
    params: CompanyAdmin.firestore.ParameterOfCollectionPath
  ) {
    const collectionReference = this.collectionReference(params)

    await setDoc(
      doc(collectionReference, userId),
      { createdAt: serverTimestamp(), userId },
      { merge: true }
    )
  }

  async delete(
    userId: string,
    params: CompanyAdmin.firestore.ParameterOfCollectionPath
  ) {
    const collectionReference = this.collectionReference(params)

    await deleteDoc(doc(collectionReference, userId))
  }

  onSnapshot({
    params,
    userId,
    onData,
  }: {
    params: CompanyAdmin.firestore.ParameterOfCollectionPath
    userId: string
    onData: (data: boolean) => void
  }): Unsubscribe {
    const documentReference = doc(this.collectionReference(params), userId)

    return onSnapshot(documentReference, (snapshot) => {
      onData(snapshot.exists())
    })
  }
}
