/**
 * 企業の管理者
 */
export interface CompanyAdmin {
  userId: string
  createdAt: Date
}

export namespace CompanyAdmin {
  export namespace firestore {
    export const collectionPath = ({ companyId }: { companyId: string }) =>
      `Company/v2/companies/${companyId}/admins`
    export type ParameterOfCollectionPath = Parameters<typeof collectionPath>[0]
  }
}
