import { useEffect, useState } from "react"
import { getFirebaseAuthInfra } from "@/infrastructure/firebase_auth/client"
import { type User } from "firebase/auth"

export const useCurrentUser = () => {
  const [stateReady, setStateReady] = useState(false)  
  const [user, setUser] = useState<User | undefined>(undefined)
  useEffect(() => {
    const auth = getFirebaseAuthInfra()

    void auth.authStateReady().then(() => {
      setUser(auth.currentUser ?? undefined)
      setStateReady(true)
    })
  }, [setUser])

  const userResult = user != null ? { userId: user.uid, email: user.email } : undefined

  return {
    ...userResult,
    stateReady
  }
}
