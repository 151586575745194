/**
 * 所属企業
 */
export interface WorkingCompany {
  companyId: string
  removed: boolean
}

export namespace WorkingCompany {
  export namespace firestore {
    export const collectionPath = ({ userId }: { userId: string }) =>
      `Company/v2/users/${userId}/workingCompanies`
    export type ParameterOfCollectionPath = Parameters<typeof collectionPath>[0]
  }
}
