import { useEffect, useState } from "react"
import { type Company } from "@/domain/features/company/v2/entities/company/company"
import { CompanyAdminRepository } from "@/domain/features/company/v2/repositories/company_admin_repository"
import { fetchCurrentWorkingCompany } from "@/domain/features/company/v2/use_cases/fetch_current_working_company"
import { getFirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/client"

import { useCurrentUser } from "@/app/_hooks/useCurrentUser"

export const useCurrentWorkingCompany = () => {
  const { userId, stateReady: authStateReady } = useCurrentUser() ?? {}
  const [stateReady, setStateReady] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [company, setCompany] = useState<Company | undefined>(undefined)

  useEffect(() => {
    const firestore = getFirebaseFirestoreInfra()

    void (async () => {
      if (userId == null) {
        return
      }
      const company = await fetchCurrentWorkingCompany({ userId, firestore })

      setCompany(company)

      if (company == null) {
        return
      }

      const existsCompanyAdmin = await new CompanyAdminRepository(
        firestore
      ).exists({
        companyId: company.companyId,
        userId,
      })

      setIsAdmin(existsCompanyAdmin)
    })().finally(() => {
      setStateReady(authStateReady)
    })
  }, [authStateReady, userId])

  return { isAdmin, company, stateReady }
}
