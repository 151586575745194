import { Company } from "@/domain/features/company/v2/entities/company/company"
import { type FirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/core/firestore"
import { type Unsubscribe } from "firebase/auth"
import { doc, getDoc, onSnapshot } from "firebase/firestore"

export class CompanyRepository {
  constructor(private readonly firestore: FirebaseFirestoreInfra) {}

  private collectionReference() {
    return this.firestore.collectionReference<Company>(
      Company.firestore.collectionPath
    )
  }

  async fetchById({
    companyId,
  }: {
    companyId: string
  }): Promise<Company | undefined> {
    const ref = doc(this.collectionReference(), companyId)
    const snapshot = await getDoc(ref)

    return snapshot.data()
  }

  onSnapshot({
    companyId,
    onData,
  }: {
    companyId: string
    onData: (data: Company) => void
  }): Unsubscribe {
    const ref = doc(this.collectionReference(), companyId)

    return onSnapshot(ref, (snapshot) => {
      onData(snapshot.data()!)
    })
  }
}
