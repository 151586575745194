import { WorkingCompany } from "@/domain/features/company/v2/entities/working_company/working_company"
import { type FirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/core/firestore"
import { getDocs, limit, query } from "firebase/firestore"

export class WorkingCompanyRepository {
  constructor(private readonly firestore: FirebaseFirestoreInfra) {}

  private collectionReference(
    params: WorkingCompany.firestore.ParameterOfCollectionPath
  ) {
    return this.firestore.collectionReference<WorkingCompany>(
      WorkingCompany.firestore.collectionPath(params)
    )
  }

  // 後々の複数企業所属の拡張性を持たせるため、所属企業はコレクションで持たせている
  // しかし、現状は１企業が前提の設計なので、limit(1) で取得する
  async fetchOrNot({
    userId,
  }: {
    userId: string
  }): Promise<WorkingCompany | undefined> {
    const ref = query(this.collectionReference({ userId }), limit(1))
    const snapshot = await getDocs(ref)

    return snapshot.docs.at(0)?.data()
  }

  // 後々の複数企業所属の拡張性を持たせるため、所属企業はコレクションで持たせている
  // しかし、現状は１企業が前提の設計なので、limit(1) で取得する
  async fetchRequired({ userId }: { userId: string }): Promise<WorkingCompany> {
    const ref = query(this.collectionReference({ userId }), limit(1))
    const snapshot = await getDocs(ref)

    return snapshot.docs.at(0)!.data()
  }
}
