export interface LatLng {
  latitude: number
  longitude: number
}

export function isLatLng(x: unknown): x is LatLng {
  return (
    typeof x === "object" &&
    x !== null &&
    "latitude" in x &&
    typeof x.latitude === "number" &&
    "longitude" in x &&
    typeof x.longitude === "number"
  )
}
