"use client"

import * as React from "react"
import { ThemeProvider as NextThemesProvider } from "next-themes"
import { type ThemeProviderProps } from "next-themes/dist/types"

export function ThemeProvider({ children, ...props }: ThemeProviderProps) {
  // クライアントサイドでのみ動作するために、useEffect 動作後のみ描画させる
  // https://github.com/pacocoursey/next-themes/issues/169#issuecomment-1495666065
  const [mounted, setMounted] = React.useState(false)
  React.useEffect(() => {
    setMounted(true)
  }, [])

  if (mounted == false) {
    return null
  }

  return <NextThemesProvider {...props}>{children}</NextThemesProvider>
}
